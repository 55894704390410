:root {
    --cPureWhite:#fff;
    --cWhite:#eee;
    --cLightGray:#9f9e99;/*#909a92*//*#111*/
    --cDarkGray:#666; /*#999*/
    --cYellow: rgb(244, 248, 4);
    --cGreen:rgb(102, 233, 51);
    --cLightBlue:#e3f2fd;
    --cBlue:rgb(31, 110, 228);
    --cBlack:#222;
    --cRed:rgb(250,3,3);
    --cTrans: rgba(0, 0, 0, 0.5);
    --cTransparent:rgba(0,0,0,0);

    --mTransition: all 0.3s linear;
    --maSpacing: 3px;
    --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
    --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
    
    --borderPureWhite:1px solid #fff;
    --borderWhite:1px solid #eee;
    --borderLightGray:1px solid #ccc;
    --borderDarkGray:1px solid #999;
    --borderGreen:1px solid rgb(102, 233, 51);
    --borderLightBlue:1px solid #e3f2fd;
    --borderYellow:1px solid rgb(244,238,4);
    --borderBlue:1px solid rgb(31, 110, 228);
    --borderBlack:1px solid #222;
    --borderRed:1px solid rgb(250,3,3);
}
  
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-size:1rem;
    border:none;
    outline:none;
    border-collapse: separate; 

}
/*----------------------------*/
.w-100{width:100%;}
.w-95{width:95%;}
.w-90{width:90%;}
.w-85{width:85%;}
.w-80{width:80%;}
.w-75{width:75%;}
.w-70{width:70%;}
.w-65{width:65%;}
.w-60{width:60%;}
.w-55{width:55%;}
.w-50{width:50%;}
.w-45{width:45%;}
.w-40{width:40%;}
.w-35{width:35%;}
.w-30{width:30%;}
.w-25{width:25%;}
.w-20{width:20%;}
.w-15{width:15%;}
.w-10{width:10%;}
.w-5{width:5%;}

.h-100{height:100%;}
.h-95{height:95%;}
.h-90{height:90%;}
.h-85{height:85%;}
.h-80{height:80%;}
.h-75{height:75%;}
.h-70{height:70%;}
.h-65{height:65%;}
.h-60{height:60%;}
.h-55{height:55%;}
.h-50{height:50%;}
.h-45{height:45%;}
.h-40{height:40%;}
.h-35{height:35%;}
.h-30{height:30%;}
.h-25{height:25%;}
.h-20{height:20%;}
.h-15{height:15%;}
.h-10{height:10%;}
.h-5{height:5%;}

/*---------------------------*/
.p-1{padding:0.5rem;}
.p-2{padding:1rem;}
.p-3{padding:1.5rem;}
.p-4{padding:2rem;}

.px-1{padding:0 0.5rem}
.px-2{padding:0 1rem}
.px-3{padding:0 1.5rem}
.px-4{padding:0 2rem}

.py-1{padding:0.5rem 0}
.py-2{padding:1rem 0}
.py-3{padding:1.5rem 0}
.py-4{padding:2rem 0}

.pl-1{padding-left:0.5rem;}
.pl-2{padding-left:1rem;}
.pl-3{padding-left:1.5rem;}
.pl-4{padding-left:2rem;}

.pr-1{padding-right:0.5rem;}
.pr-2{padding-right:1rem;}
.pr-3{padding-right:1.5rem;}
.pr-4{padding-right:2rem;}

.pt-1{padding-top:0.5rem;}
.pt-2{padding-top:1rem;}
.pt-3{padding-top:1.5rem;}
.pt-4{padding-top:2rem;}

.pb-1{padding-bottom:0.5rem;}
.pb-2{padding-bottom:1rem;}
.pb-3{padding-bottom:1.5rem;}
.pb-4{padding-bottom:2rem;}
/*-----------------------------*/
.m-1{margin:0.5rem;}
.m-2{margin:1rem;}
.m-3{margin:1.5rem;}
.m-4{margin:2rem;}

.mx-1{margin:0 0.5rem;}
.mx-2{margin:0 1rem;}
.mx-3{margin:0 1.5rem;}
.mx-4{margin:0 2rem;}

.my-1{margin:0.5rem 0;}
.my-2{margin:1rem 0;}
.my-3{margin:1.5rem 0;}
.my-4{margin:2rem 0;}

.ml-1{margin-left:0.5rem;}
.ml-2{margin-left:1rem;}
.ml-3{margin-left:1.5rem;}
.ml-4{margin-left:2rem;}

.mr-1{margin-right:0.5rem;}
.mr-2{margin-right:1rem;}
.mr-3{margin-right:1.5rem;}
.mr-4{margin-right:2rem;}

.mt-1{margin-top:0.5rem;}
.mt-2{margin-top:1rem;}
.mt-3{margin-top:1.5rem;}
.mt-4{margin-top:2rem;}

.mb-1{margin-bottom:0.5rem;}
.mb-2{margin-bottom:1rem;}
.mb-3{margin-bottom:1.5rem;}
.mb-4{margin-bottom:2rem;}

/*-----------------------*/
.bd-black     {border-radius:0.3rem;border:var(--borderBlack) }
.bd-blue      {border-radius:0.3rem;border:var(--borderBlue)}
.bd-pureWhite {border-radius:0.3rem;border:var(--borderPureWhite)}
.bd-lightGray {border-radius:0.3rem;border:var(--borderLightGray)}
.bd-darkGray  {border-radius:0.3rem;border:var(--borderDarkGray)}
.bd-green     {border-radius:0.3rem;border:var(--borderGreen)}
.bd-yellow    {border-radius:0.3rem;border:var(--borderYellow)}
.bd-lightBlue {border-radius:0.3rem;border:var(--borderLightBlue)}
.bd-red       {border-radius:0.3rem;border:var(--borderRed)}
.bd-none      {border-radius:0.3rem;border:none}

.bgc-black    {background-color:var(--cBlack) }
.bgc-blue     {background-color:var(--cBlue) }
.bgc-pureWhite{background-color:var(--cPureWhite) }
/*.bgc-lightGray{background-color:var(--cLightGray) }*/
/*
.bgc-lightGray {
    background: rgb(231,231,236);
    background: radial-gradient(circle, rgba(220,220,220,1) 0%, rgba(124,124,128,0.9) 100%);
}
*/
.bgc-lightGray {
    /*background: rgba(220,220,220,0.7);*/
    background: radial-gradient(circle, rgba(200,200,200,0.9) 0%, rgba(170,170,170,0.5) 100%);
}
/*background: radial-gradient(circle, rgba(220,220,220,0.7) 0%, rgba(190,190,190,0.7) 100%);*/


.bgc-darkGray {background-color:var(--cDarkGray) }
.bgc-green    {background-color:var(--cGreen) }
.bgc-yellow   {background-color:var(--cYellow) }
.bgc-lightBlue{background-color:var(--cLightBlue) }
.bgc-red      {background-color:var(--cRed) }
.bgc-trans    {background-color:transparent;}

/*--------------------------*/
.bill-p{
    font-size: 1.2rem;
}

.bill-p:hover{
    color:blue;
    cursor:pointer;
}

.circle-p{
    background-color:white;
    color:black;
    
}
.circle-p:hover{
    background-color:var(--cYellow);
    color:blue;
    cursor: pointer;
}

.grid-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.grid-flextileMother {
    width:15rem;
    height:15rem;
    margin:0.2rem;
}
/*
.grid-flextile{
    width:10rem;
    height:10rem;
    margin:0.2rem;
    margin-bottom:8rem;
    border-radius:0.5rem;
}
*/
.grid-flextile{
    width:100%;
    /*height:80%;*/
    border-radius:0.5rem;
}
.grid-flextile2{
    min-width:9rem;
    margin:0.2rem;
    border-radius:0.5rem;
}

.grid-flextile3{
    min-width:20rem;
    margin:0.2rem;
    border-radius:0.5rem;
}

.grid-square {
    display: grid;
    grid-template-columns:repeat(6,1fr);
    grid-gap: 5px;
}
.grid-square div {
    background-color:white;
    aspect-ratio: 1;
    border-radius: 0.5rem;
}

.grid-price {
    /*position:absolute;*/
    
    width:100%;
    height:35%;
    opacity: 0;
}

.grid-price:hover {
    opacity: 0.9;
}

.grid-stock {
    background-color: white;
}

.grid-stock:hover{
    background-color:var(--cYellow);
}

.group-img{
    width:100%;
    height:100%;
    opacity: 0;
}
.group-img:hover{
    opacity: 0.9;
}

.navLink{
    width:6rem;
    height:100%;
}
.navLink:hover{
    background-color:rgba(244, 248, 4,0.5);

}



@media (max-width: 1600px) {
    .grid-square {
        display: grid;
        grid-template-columns:repeat(6,1fr);
        grid-gap: 5px;
    }
    .grid-flextile{
        width:7rem;
        height:7rem;
    }
    .grid-flextile2{
        min-width:9rem;
    }
}
@media (max-width: 1200px) {
    .grid-square {
        display: grid;
        grid-template-columns:repeat(4,1fr);
        grid-gap: 5px;
    }
  
    .grid-flextile{
        width:6rem;
        height:6rem;
    }
    .grid-flextile2{
        min-width:8rem;
    }
}

@media (max-width: 768px) {
    .grid-square {
        display: grid;
        grid-template-columns:repeat(2,1fr);
        grid-gap: 5px;
    }
    .hide-logouttool{
        display:none;
    }
    .grid-flextile{
        width:5rem;
        height:5rem;
    }
    .grid-flextile2{
        min-width:7rem;
    }

}
/*---------------------------*/
.xc1 {width: 8.33%;}
.xc2 {width: 16.66%;}
.xc3 {width: 25%;}
.xc4 {width: 33.33%;}
.xc5 {width: 41.66%;}
.xc6 {width: 50%;}
.xc7 {width: 58.33%;}
.xc8 {width: 66.66%;}
.xc9 {width: 75%;}
.xc10 {width: 83.33%;}
.xc11 {width: 91.66%;}
.xc12 {width: 100%;}

@media (max-width: 1600px) {
 .lc1 {width: 8.33%;}
 .lc2 {width: 16.66%;}
 .lc3 {width: 25%;}
 .lc4 {width: 33.33%;}
 .lc5 {width: 41.66%;}
 .lc6 {width: 50%;}
 .lc7 {width: 58.33%;}
 .lc8 {width: 66.66%;}
 .lc9 {width: 75%;}
 .lc10 {width: 83.33%;}
 .lc11 {width: 91.66%;}
 .lc12 {width: 100%;}

 .lc-hide {display:none;}

 .Modal-box{
     width:66.66%
 }
}


.keyPad{
    display:none;
}

@media (max-width: 1200px) {
 .mc1 {width: 8.33%;}
 .mc2 {width: 16.66%;}
 .mc3 {width: 25%;}
 .mc4 {width: 33.33%;}
 .mc5 {width: 41.66%;}
 .mc6 {width: 50%;}
 .mc7 {width: 58.33%;}
 .mc8 {width: 66.66%;}
 .mc9 {width: 75%;}
 .mc10 {width: 83.33%;}
 .mc11 {width: 91.66%;}
 .mc12 {width: 100%;}

 .mc-hide {display:none;}
 .Modal-box {width: 83.33%;}
}

@media (max-width: 768px) {
  /* For mobile phones: */
  .sc1 {width: 8.33%;}
  .sc2 {width: 16.66%;}
  .sc3 {width: 25%;}
  .sc4 {width: 33.33%;}
  .sc5 {width: 41.66%;}
  .sc6 {width: 50%;}
  .sc7 {width: 58.33%;}
  .sc8 {width: 66.66%;}
  .sc9 {width: 75%;}
  .sc10 {width: 83.33%;}
  .sc11 {width: 91.66%;}
  .sc12 {width: 100%;}
  
  .sc-hide {display:none;}
  .Modal-box {width: 100%;}

  .keyPad {
      display: block;
  }
}

.app-container{
    overflow: hidden;
}

.app-navbar{
    height:5vh;
}

.app-route{
    height:95vh;
}

.print-container{
    margin:"1rem";
    page-break-inside: avoid;
}

.print-footer{
    page-break-inside: avoid;
}

/*
@page {
    size:A4 portrait;
}
*/

/*-------------------------*/

/*--------------------------*/

h1,h2,h3,h4,h5,h6,p,label{
    background-color:transparent;
    word-wrap:break-word;
}
h1{font-size:2rem;font-weight: bold;}
h2{font-size:1.8rem;font-weight: bold;}
h3{font-size:1.6rem;font-weight: bold;}
h4{font-size:1.4rem;font-weight: bold;}
h5{font-size:1.2rem;font-weight: bold;}
h6{font-size:1rem;font-weight: bold;}
p,label{font-size:0.8rem;}


/*--------------------------*/
.flex-center-baseline{
    display:flex;
    justify-content:center;
    align-items:baseline;
    flex-wrap:wrap;
    width:100%;
}

.flex-center-top{
    display:flex;
    justify-content:center;
    align-items:top;
    flex-wrap:wrap;
    width:100%;
}
.flex-center-center{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap:wrap;
    width:100%;
}

.flex-center-start{
    display:flex;
    justify-content:center;
    align-items:flex-start;
    flex-wrap:wrap;
    width:100%;
}

.flex-center-end{
    display:flex;
    justify-content:center;
    align-items:flex-end;
    flex-wrap:wrap;
    width:100%;
}

.flex-center-stretch{
    display:flex;
    justify-content:center;
    align-items:stretch;
    flex-wrap:wrap;
    width:100%;
}

.flex-wrap{flex-wrap:wrap;}
.flex-no-wrap{flex-wrap:nowrap;}

.jc-start{justify-content:flex-start;}
.jc-end{justify-content:flex-end;}
.jc-center{justify-content: center;}
.jc-space-between{justify-content: space-between;}
.jc-space-around{justify-self: space-around;}

.at-start{align-items:start;}
.at-center{align-items:center;}
.at-end{align-items:end;}
.at-baseline{align-items:baseline;}
.at-stretch{align-items:stretch;}

.alignSelfStretch{align-self:stretch;}
.alignSelfStart{align-self: start;}
.alignSelfCenter{align-self: center;}
.alignSelfEnd{align-self: end;}

/*--------------------*/
.dp-none{display:none;}
.vsblt-visible{visibility:visible}
.vsblt-hidden{visibility:hidden}
/*-----------------*/
.overflow-scroll{overflow:scroll;}
.overflow-hidden{overflow:hidden;}
/*----------------------*/
input,select,option {
    font-size:1rem;/*0.8rem;*/
    background-color:var(--cPureWhite);
    padding:0.1rem;
    border:var(--borderLightGray);
    border-radius:0.3rem;
    height: 1.7rem;
    width:100%
}
textarea {
    font-size:1rem; /*0.8rem;*/
    background-color:var(--cPureWhite);
    padding:0.1rem;
    border:var(--borderLightGray);
    border-radius:0.3rem;
    width:100%
}
input[type=checkbox]{
    font-size:0.8rem;
    background-color:var(--var-cGreen);
    padding:0.1rem;
    border:var(--borderWhite);
    border-radius:0.3rem;
    margin:0 0.2rem;
    align-self: flex-end;
}
input:hover,input:focus,
select:hover,select:focus,
input[type=checkbox]{
    border:var(--borderDarkGray);
}

/*--------------*/
button{
    font-size: 1rem;
    background-color: #4b6d62;
    color:var(--cWhite);
    font-weight: bold;
    padding:0.1rem;
    border:var(--borderDarkGray);
    min-width: 4rem;
    min-height: 2rem;
    box-shadow: 5px 5px 20px white;
    border-radius:5px!important;
    margin:0 10px!important;
}

div {
    font-size:1rem;
}

button:hover,button:focus{
   border:var(--borderYellow);
   opacity:1;
   cursor:pointer;
}

.hideButton{    
    background-color:transparent;
    border:none;
    color:transparent;
    box-shadow:none;
}
.hideButton:hover,.hideButton:focus{
    border:none
}
/*---------------*/

.sm-icon,.md-icon,.lg-icon,.xl-icon{
    background-color: transparent;
    border-radius: 15%;
    margin:auto 0.4rem;
    cursor:pointer;
}
.sm-icon:hover,.sm-icon:focus,
.md-icon:hover,.md-icon:focus,
.lg-icon:hover,.lg-icon:focus,
.xl-icon:hover,.xl-icon:focus{
    background-color:var(--cYellow);
}

.sm-icon{font-size:1.5rem;}
.md-icon{font-size:2rem;}
.lg-icon{font-size:2.5rem;}
.xl-icon{font-size:3rem;}

.red-icon{color:red;}
.yellow-icon{color:yellow;}
.green-icon{color:green;}

/*-----------------------*/

/*---------------------*/
.transparent{
    background-color: "";
}

.badge-frame-xc12,
.badge-frame-xc6-left,
.badge-frame-xc6-right{
    /*position:fixed; bottom:0; height:3rem;z-index:100;*/
    
    background: rgb(241,239,225);
    background: radial-gradient(circle, rgba(241,239,225,1) 1%, rgba(125,160,151,1) 93%);
    /*
    background: rgb(38,68,120);
    background: linear-gradient(0deg, rgba(38,68,120,1) 0%, rgba(40,68,116,0.99) 44%, rgba(77,121,199,1) 100%);
    
    */
    /*background-color: #7da097;*/
    display:flex;
    justify-content: center;
    /*overflow:auto;*/
}
.badge-frame-xc12{
    left:0;
    height:100%;
    width:100%;
}
.badge-frame-xc6-left{
    left:0;
    width:50%;
}
.badge-frame-xc6-right{
    right:0;
    width:50%;
}

/*============*/

.page-center{
    display:grid;
    place-items:center;
    width:100%;
    height:100%;
    background: url("./images/icecream.jpg") center/cover no-repeat;
}

.page-badge{
    width:100vw;
    height:100vh;
    padding-top:3rem;
    padding-bottom:3rem;
    background-color:var(--cDarkGray);
}

/*----------------------*/

.Modal-background{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh; 
    z-Index:200;
    background-color:var(--cTrans);
    display: grid;
    place-items: center;
  }
  
  .Modal-box{
    min-width:40%;
    max-width:100%;
    max-height:100%;
    /*background-color:var(--cLightGray);*/
    background: rgb(231,231,236);
    background: radial-gradient(circle, rgba(220,220,220,1) 0%, rgba(124,124,128,0.9) 100%);
    border-radius: 0.3rem;
    padding:1rem;
    overflow: auto;
  }
  .Modal-box2{
    /*background-color:var(--cLightGray);*/
    background: rgb(231,231,236);
    background: radial-gradient(circle, rgba(220,220,220,1) 0%, rgba(124,124,128,0.9) 100%);
    border-radius: 0.3rem;
    padding:1rem;
    overflow: auto;
  }
  .Modal-header{
    display:flex;
    justify-content:center;
    align-items:center;
    /*background-color:var(--cLightGray);*/
    background-color: rgba(250,250,250,0);
    padding:0.2rem;
    margin-bottom:0.5rem;
    width:100%;
  }
  .Modal-body{
    display:flex;
    justify-content:center;
    align-items:center;
    /*background-color:var(--cLightGray);*/
    background-color: rgba(250,250,250,0);
    padding:0.2rem;
    margin-bottom:1rem;
    width:100%;
  }

  .ModalInsideBody{
    width:100%;
    background-color: rgba(250,250,250,0);
    /*background-color:var(--cLightGray);*/
  }
  
  .Modal-footer{
    display: flex;
    justify-content: flex-end;
    padding:0.2rem;
    margin-bottom:0.5rem;
    width:100%; 
    background-color: rgba(250,250,250,0);
  }
  
  .Modal-cancel-button{
    /*background-color: var(--cLightGray);*/
    background: rgb(231,231,236);
    background: radial-gradient(circle, rgba(220,220,220,1) 0%, rgba(124,124,128,0.9) 100%);
    color:var(--cBlack);
  }

 .divlink:hover{
    color:red;
 }
 
 .iconbox{
     width:3.5rem;
     display:flex;
     align-items:center;
     justify-content:center;
     height:100%;
 }
.iconbox2{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
}

.stickerIcon{
    font-size:1.7rem;
    color:black;
}

 .iconbox:hover,.iconbox2:hover{
    background-color:rgba(244, 248, 4,0.5);
    cursor:pointer;

}

.ft-brown{
    color:#634739;
}

.ft-red{
    color:red;
}


.billQueueBox {
    background-color:rgba(255,255,255,0.7);
}

.billQueueBox:hover {
    background-color:rgba(255,255,255,0.3);
}

.table-setting:hover{
    color:red;
}

@media print{
    .check-print > :not(.printable) {display:none;}
    .hide-on-print{display:none;}
    
    /*
    .hide-on-screen{
        background-color: white;
    }
    */
    .page-center,.page-center-badge,.page-badge{
      width:100%;
      height:100%;
    }
    /*.overflow-hide-on-print{overflow:hidden;}  */
    .app-navbar{
        height:0;
    }
    .app-container{
        overflow: visible;
    }
    .show-on-print{
        position: absolute;
        /*...background-color: green;...*/
        width: 100vw;
        height: 100vh;
        top:0;
        left:0;
    }
}
/*
.hide-on-screen{display:none;}
*/
@media screen{
    .check-print > .printable {display:none;}
    .hide-on-screen{
        display:none;
    }
    /*.overflow-hide-on-print{overflow-x:auto;overflow-y:auto;}*/
}

