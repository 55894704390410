.container *{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.container{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    z-Index:200;
}

.calendar{
    width:50%;
    height:50%;
    background-color: #ccc;
    border-radius: 10px;
    border:black solid 1px;
    box-shadow: 0 0.5rem 0.3rem rgba(0,0,0,0.4);
}

.year{
    width:10%
}

.month {
    width:100%;
    height:12%;
    background-color: #999;
    border-radius: 10px 10px 0 0;
    display:flex;
    justify-content: space-between;
    align-items:center;
    text-align:center;
}

.month div{
    color:#222;
    font-size:1.3rem;
    font-weight:800;
    text-transform:uppercase;
}

.arrow{
    font-size:4rem;
    font-weight: 900;
    width:15%;
    height:100%;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}
.arrow:hover{
    background-color: rgba(250,250,250,0.4);
}

.month-header:hover{
    background-color: rgba(250,250,250,0.4);
}

.weekdays {
    width:100%;
    height:12%;
    background-color: #999;
    display:flex;
    align-items:center;
}

.weekdays div{
    color:#222;
    font-size:1.2rem;
    font-weight:800;
    width:calc(100%/7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.days{
    width:100%;
    height:76%;
    display:flex;
    justify-content: space-between;
    align-items:center;
    flex-wrap:wrap;
}

.days div{
    font-size:1rem;
    color:#222;
    font-weight: 700;
    width:calc(100%/7);
    height:calc(100%/7);
    display:flex;
    justify-content: center;
    align-items: center;
}
.days .today{
    background-color: rgb(31, 110, 228,0.3);
    color:rgba(255, 0, 0, 0.712);
}

.days div:hover:not(.not-current-date){
    background-color:rgba(31,110,228,0.7);
}

.days .not-current-date{
    color:#777;
}   
.prev-date,.next-date{
    opacity:0.5;
    color:black;
}
@media (max-width: 768px) {
    .calendar{
        width:90%
    }
}