 .sticker-p{
     padding:0;
     margin:0;
     color:black;
 }


.sticker-data:nth-child(even) {
    background-color:var(--cLightGray);
}


.modal-bg{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh; 
    z-Index:200;
    background-color:var(--cTrans);
    display: grid;
    place-items: center;
  }
  