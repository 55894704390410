.home-hero {
    height:100vh; width:100vw;
    /*opacity: 0.6;*/
    /*background: url("../../images/waterfall.jpg") center/cover no-repeat transparent;*/
    /*background-color:rgb(51,51,153);*/
    position:fixed;
    top:0;left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:1;
  }
.home-bg-screen{
    height:100%; width:100%;
    background-color: rgba(150,150,150,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: linear-gradient(0deg, rgba(38,68,120,1) 0%, rgba(40,68,116,0.99) 44%, rgba(250,250,250,1) 100%);*/
    /*background: rgb(63,243,251);
    background: radial-gradient(circle, rgba(63,243,251,0.9161290322580645) 0%, rgba(63,97,251,1) 100%);*/
    background: rgb(200,27,217);
    background: linear-gradient(0deg, rgba(200,27,217,1) 0%, rgba(96,27,152,1) 100%);
}


/* #601b98
   #a317b1 */

.home-box{
    width:100%;
    
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
}

.home-tile{
    position:relative;
}


.home-tile:hover{
    transform:scale(1.02);
    transition:transform 100ms linear;
}

.home-tile2{
    position:relative;
    grid-row:1/3;
    grid-column:1/2;
}


.home-tile2:hover{
    transform:scale(1.02);
    transition:transform 100ms linear;
}


.home-link{
    width:8rem;
    height:8rem;
    border: gray solid 1px;
    background: rgb(236,170,120);
    background: linear-gradient(0deg, rgba(236,170,120,1) 14%, rgba(255,255,255,1) 94%);
    display:grid;
    place-items:center;
    border-radius: 30%;
    box-shadow: 5px 5px 20px rgb(10,10,10);
    margin:1rem;
}
.home-link:hover{
    /*background:#F0BE98;*/
    background:#EBE30E;
    border:1px solid #EBE30E;
}
.home-icon{
    font-size:5rem;
}

.home-grid{
    width:70vw;
    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
    grid-gap:1rem;
    grid-auto-rows:15vh;
}

.triangle1{
    position:fixed;
    transform: rotate(10deg);
    top:60%;
    right:5%;
    width: 0;
    height: 0;
    border-bottom: 20vw solid rgba(100,100,100,0.1);
    border-right: 30vw solid transparent;
}

.triangle2{
    position:fixed;
    transform: rotate(100deg);
    top:50%;
    left:20%;
    width: 0;
    height: 0;
    border-bottom: 20vw solid rgba(100,100,100,0.1);
    border-right: 30vw solid transparent;
}
.triangle3{
    position:fixed;
    transform: rotate(330deg);
    top:0%;
    left:0%;
    width: 0;
    height: 0;
    border-bottom: 60vw solid rgba(100,100,100,0.1);
    border-right: 20vw solid transparent;
}
.triangle4{   
    position:fixed;
    transform: rotate(130deg);
    top:20%;
    left:60%;
    width: 0;
    height: 0;
    border-bottom: 30vw solid rgba(100,100,100,0.1);
    border-right: 20vw solid transparent;
}
.triangle5{   
    position:fixed;
    transform: rotate(250deg);
    top:10%;
    left:80%;
    width: 0;
    height: 0;
    border-bottom: 40vw solid rgba(100,100,100,0.1);
    border-right: 35vw solid transparent;
}



@media (max-width: 1600px) {
    .home-grid{
        width:70vw;
        grid-template-columns:1fr 1fr 1fr 1fr;
    }

   
}

@media (max-width: 1200px) {
    .home-grid{
        width:80vw;
        grid-template-columns:1fr 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .home-grid{
        width:98vw;
        grid-template-columns:1fr 1fr;
        grid-auto-rows:15vh;

    }
    
    .home-tile2{
        grid-row:1/2;
        grid-column:1/2;
    }

}
  /*
  .roomsHero {
    background-image: url("./images/room-2.jpeg");
    min-height: 60vh;
  }
  */
  